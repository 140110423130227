<template>
  <v-container>
    <div class="header">
      <span>
        <h3>Serviços por cliente</h3>
      </span>
      <div class="grow"/>
       <v-text-field
          @keyup="filtrarClientes"
          @keydown="resetarFiltroClientes"
          filled
          solo
          hide-details
          append-icon="mdi-magnify"
        ></v-text-field>
    </div>
    <section class="wrapper-list" v-if="loading" >
      <!-- lista de usuarios -->
      <v-data-table 
        :items="clientes"
        :headers="headers"
        :items-per-page="6"
        @click:row="(item) => selecionarCliente(item)">     
      </v-data-table>
    </section>
    <list-skeleton v-if="!loading"/>
    <v-dialog v-model="modalCliente" width="95%" max-width="1024px">
      <v-card>
        <v-toolbar>
        Sistemas e Módulos
        </v-toolbar>        
        <v-card-text>
          <v-select 
            :items="sistemas"
            item-text="nome"
            item-value="id"
            @change="(item) => alterarModulos(item)"
            placeholder="Esolha um sistema"
            v-model="sistemaSelecionado"
            />
          <v-list>
            <v-list-item v-for="modulo in modulosSelecionados" :key="modulo">
              <div class="modulo-row">
                <input 
                  :id="modulo.nome"
                  type="checkbox" 
                  style="margin-right: .5em"
                  @click="syncVinculo(clienteSelecionado, sistemaSelecionado, modulo.id)"
                  :checked="clienteSelecionado.sistemas != null 
                  && clienteSelecionado.sistemas[sistemaSelecionado] 
                  && clienteSelecionado.sistemas[sistemaSelecionado].includes(modulo.id)"
                />
                <label :for="modulo.nome">
                  {{modulo.nome}}
                </label>
              </div>
            
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ModuloServico from '@/services/modulos.service.js'
import ClienteServico from '@/services/cliente.service.js'
import SistemaServico from '@/services/sistema.service.js'
import ListSkeleton from '../Loading/ListSkeleton.vue'
export default {
  components: { ListSkeleton },
  data(){
    return{
      loading: false,
      headers: [
        { text: 'Id', align: 'start', value: 'id' },
        { text: 'nome', align: 'start', value: 'nome' },
        { text: 'Contato', align: 'start', value: 'contato1' },
      ],
      clientes: [],
      clientesDB: [],
      sistemas: [],
      modulos: [],
      modalCliente: false,
      clienteSelecionado: {},
      modulosSelecionados: [],
      sistemaSelecionado: null,

    }
  },
  methods:{
    async requisitarSistemas(){
      this.sistemas = await SistemaServico.buscar().then(res=> res.data)
    },
    async requisitarModulos(){
      this.modulos = await ModuloServico.buscar().then(res=> res.data)
    },    
    async requisitarClientes(){
      this.clientes = await ClienteServico.buscar().then(res=> res.data)
      this.clientesDB = JSON.parse(JSON.stringify(this.clientes))
    },
    filtrarClientes(e){
      let data = this.clientesDB.filter((cliente) => {
        // return Object.values(cliente).some((attr) => String(attr).toLowerCase().includes(e.target.value))
        return cliente.nome.toLowerCase().includes(e.target.value.toLowerCase())
      });
      this.clientes = data
    },
    resetarFiltroClientes(e){
      if(e.target.value.length == 1){
        this.clientes = JSON.parse(JSON.stringify(this.clientesDB))
      }
    },
    async vincularModulo(data){
      await ModuloServico.vincular(data)
        .then(() => {
          console.log('vinculado')
        })
        .catch((e) => {
          console.log(e)
          alert('error')
        })
    },
    async desvincularModulo(data){
      await ModuloServico.desvincular(data)
        .then(() => {
          console.log('desvinculado')
        })
        .catch((e) => {
          console.log(e)
          alert('error')
        })
    },    
    syncVinculo(cliente, sistema, modulo){
      let index = this.clientes.findIndex(el => el.id == cliente.id)
      let client = this.clientes[index]
      if(client.sistemas != null 
        && client.sistemas[this.sistemaSelecionado] 
        && client.sistemas[this.sistemaSelecionado].includes(modulo)){
        this.desvincularModulo({idcliente: client.id, idsistema: sistema, idmodulo: modulo})
        let index = this.clientes.findIndex(el => el.id == client.id)
        this.clientes[index].sistemas[sistema] = this.clientes[index].sistemas[sistema].filter(el => el != modulo)
      }else{
        this.vincularModulo({idcliente: client.id, idsistema: sistema, idmodulo: modulo})
        let index = this.clientes.findIndex(el => el.id == client.id)
        if(this.clientes[index].sistemas == null){
          this.clientes[index].sistemas = {}
          this.clientes[index].sistemas[sistema] = [modulo]
        }else if(Array.isArray(this.clientes[index].sistemas[sistema])){
          this.clientes[index].sistemas[sistema].push(modulo)
        }
      }
    },
    selecionarCliente(cliente){
      if(Array.isArray(cliente.sistemas)){
        let sistemas = {}
        for(let i=0; i<this.sistemas.length; i++){
          cliente.sistemas
            .filter(item => item.idsistema == this.sistemas[i].id)
            .map(item => {
              if(Object.keys(sistemas).includes(item.idsistema.toString())){
                sistemas[item.idsistema].push(item.idmodulo)
              }else{
                sistemas[item.idsistema] = [item.idmodulo]
              }
            })  
        }
        cliente['sistemas'] = sistemas
      }
      this.clienteSelecionado = cliente
      this.modalCliente = true
    },
    alterarModulos(item){
      this.modulosSelecionados = []
      this.modulosSelecionados = this.modulos.filter(el => el.idsistema == item)
    }
  },
  async mounted(){
    await this.requisitarSistemas()
    await this.requisitarClientes()
    await this.requisitarModulos()
    this.loading = true
  }
}
</script>

<style lang="scss" scoped>
.header{
  display: flex;
  padding: 1em;
  margin: 0 auto;
  width: 60vw;
  max-width: 100%;  

  >h3{
    letter-spacing: 0.02em;
  }
  margin: 1em auto;
  background: rgba(215,211,222,0.5);
  height: fit-content;
  border-radius: 10px;
  >span{
    display: flex;
    flex-direction: row;
    align-items: center;
    >h3{
        margin-right: .5em;
    }
  }
  @media screen and (max-width: 768px) {
      width: 100% !important;      
      >span{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
  }
}

.section-header{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1em 0;
}

.section-nav{
  display: flex;
  align-items: center;
  justify-content: center;

  ul{
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #eff3e7;
    padding: 1em;
    width: 100%;

    li{
      margin: 0 .5em;
      padding: 1em 1.5em;
      border-radius: 50px;
      background: #eff3e7;
  box-shadow:  4px 4px 10px #d9d9d9,
             4px 4px 10px #ffffff;
    }
  }
}

.wrapper-list{
  margin: 0 auto;
  width: 60vw;
  max-width: 100%;
  @media screen and (max-width: 768px) {
    width: 90vw;    
  }
}

.btn{
      padding: .75em 1em;
      line-height: 1.5;
      color: white;
      font-weight: bold;
      letter-spacing: 0.02em;
      background: #1377f2;
      border-radius: 10px;
      text-transform: capitalize;
      margin: auto 0;
  }

.grow{
  flex: 1;
}

.modulo-row{
  display: flex;
  align-items: center; 
  width: fit-content;
}

</style>