<template>
  <div>
    <RelacaoModulosCliente />
  </div>
</template>

<script>
import RelacaoModulosCliente from '@/components/Modulos/RelacaoModulosCliente'
export default {
  components: {RelacaoModulosCliente},
  data(){
    return{}
  }
}
</script>

<style>

</style>